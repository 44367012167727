import styled, { keyframes } from 'styled-components';
import sideImg from '../../../assets/images/side.png';

export const slideFromLeft = keyframes`
  0%{
    position: relative;
    left: -100%;
    opacity:0;

  }
  100%{
    position: relative;
    left:0;
    opacity: 1;
  }
`;

export const Warper: any = styled.aside`
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid rgb(255, 255, 255, 0.4);
  background: rgb(0, 0, 0, 0.4);
  animation: ${(props: any) => (props.firstEnter ? slideFromLeft : null)} 1s
    ease-in-out;
`;
export const Content = styled.div`
  height: 100%;
`;

export const Mask = styled.div`
  width: 11rem;
  height: 100%;
  position: fixed;
  top: 0;
  background: url(${sideImg});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
`;

export const UserInfor = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;
export const ImgLogo = styled.img`
  width: 7.5rem;
  /* height: 4rem; */
  /* border-radius: 50%; */
  /* border: 1px solid var(--primary-color); */
  margin: 3rem auto 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  filter: brightness(1.5);
`;

export const Title = styled.p`
  color: #fff;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  margin: 0.5rem;
`;
export const SubTitle = styled.p`
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin: 0.5rem;
`;

export const MenuWarper = styled.div`
  margin-top: 0.9rem;
  z-index: 3;
  position: relative;
`;

export const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: start;
`;

export const MenuItem = styled.li`
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  color: #fff;
  cursor: pointer;
  width: 100%;

  .active {
    background-color: var(--primary-color);
  }
  :hover {
    background-color: var(--primary-color);
  }
  z-index: 10;
  span,
  i {
    z-index: 3;
    background: transparent;
  }
`;
