import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';

export interface ToasterInterface {
  message: string;
  summary: string;
  type: string;
}

const initialState: ToasterInterface = {
  message: '',
  summary: '',
  type: '',
};

export const ToasterSlice = createSlice({
  name: 'toaster',
  initialState,

  reducers: {
    showToaster: (state, actions) => {
      state.type = actions.payload.type;
      state.summary = actions.payload.summary;
      state.message = actions.payload.message;
    },
  },
});

export const { showToaster } = ToasterSlice.actions;

export const toasterMessage = (state: RootState) => state.toaster;

export default ToasterSlice.reducer;
