import styled, { keyframes } from 'styled-components';

export const slideFromLeft = keyframes`
  0%{
    transform: translateX(-1rem) ;
    opacity:0
  }
  100%{
    transform: translateX(0) ;
    opacity:1
    
  }
`;

export const Content = styled.div`
  position: relative;
  margin-right: 1rem;
  svg[data-icon='bell'] {
    transform: scale(1.5);
    position: fixed;
    top: 4.8rem;
    right: 1.5rem;
    color: var(--primary-color);
    cursor: pointer;
  }
`;
