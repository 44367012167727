import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../service/store/auth/authSlice';
import selectedColReducer from '../service/store/selectedCol/selectedColSlice';
import userInfoReducer from '../service/store/userInfo/userInfoSlice';
import loadingReducer from '../service/store/loading/loadingSlice';
import dialogeReducer from '../service/store/dialoge/dialogeSlice';
import confirmationReducer from '../service/store/confirmationDialog/confirmationDialoge';
import toasterReducer from '../service/store/toaster/toasterSlice';
import permissionsReducer from '../service/store/permissions/permissionsSlice';
import statusReducer from '../service/store/status/statusSlice';
import pdfReducer from '../service/store/openPdf/openPdfSlice';
import eleReducer from '../service/store/selectedEle/selectedEleSlice';
import notificationsReducer from '../service/store/notifications/notificationsSlice';
import logger from 'redux-logger';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    selectCol: selectedColReducer,
    userInfo: userInfoReducer,
    loading: loadingReducer,
    dialoge: dialogeReducer,
    confirmation: confirmationReducer,
    toaster: toasterReducer,
    permissions: permissionsReducer,
    status: statusReducer,
    pdf: pdfReducer,
    ele: eleReducer,
    notifications: notificationsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  // .concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
