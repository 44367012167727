import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';
import { getUserInfo } from './user.api';
import { UserInfo } from './userInfo.interface';

const initialState: UserInfo = {
  id: 0,
  username: '',
  title: '',
  email: '',
  role: { id: 0, name: '' },
  permissions: [],
  department: undefined,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(20))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getuserInfoAsync = createAsyncThunk(
  'user/getUserInfoData',
  async () => {
    const response = await getUserInfo();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserInfo: (state, action: { type: string; payload: UserInfo }) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.title = action.payload.title;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.department = action.payload.department;
    },
    removeUserInfo: (state) => {
      return initialState;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
});

export const { setUserInfo, removeUserInfo } = userInfoSlice.actions;

export const selectUserInfo = (state: RootState) => state.userInfo;

export default userInfoSlice.reducer;
