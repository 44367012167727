import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://127.0.0.1:8000/api/',
});

export const axiosRefresh = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}refresh`,
  // baseURL: 'http://127.0.0.1:8000/api/refresh',
});

export default axiosInstance;
