import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';

export interface authState {
  status: { id: any; name: string };
}

const initialState: authState = {
  status: { name: 'pending', id: '1' },
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,

  reducers: {
    statusChange: (state, actions) => {
      state.status = actions.payload;
    },
  },
});

export const { statusChange } = statusSlice.actions;

export const selectStatus = (state: RootState) => state.status;

export default statusSlice.reducer;
