import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';

export interface authState {
  isAuth: boolean;
  token: string;
}

const initialState: authState = {
  isAuth: false,
  token: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    signIn: (state, actions) => {
      state.isAuth = true;
      state.token = actions.payload;
      state = { ...actions.payload };
    },
    signOut: (state) => {
      localStorage.clear();
      return initialState;
    },
  },
});

export const { signIn, signOut } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
