import axiosInstance from '../../core/axiosInstance';

export const getNotifications = async () => {
  const notifications: any = await axiosInstance.get('notifications');

  return notifications.data.data;
};

export const readNotification = async (id: any) => {
  const notification: any = await axiosInstance.put(`notifications/${id}/read`, {});
  return notification;
};

export const readAllNotifications = async () => {
  const notification = await axiosInstance.put(`notifications/read-all`, {});
  return notification;
};
