import React from 'react';
import axiosInstance, { axiosRefresh } from './axiosInstance';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Interceptors = () => {
  const navigate = useNavigate();
  axiosInstance.interceptors.request.use(
    function (config: any) {
      if (config.url !== '/login') {
        const token = localStorage.getItem('userToken');

        config.headers.Authorization = token
          ? `Bearer ${JSON.parse(token).access_token}`
          : '';
      }
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

  axiosRefresh.interceptors.request.use(function (config: any) {
    if (config.url !== '/login') {
      const token = localStorage.getItem('refreshToken');

      config.headers.Authorization = token
        ? `Bearer ${JSON.parse(token).access_token}`
        : '';
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (res: any) => {
      return res;
    },
    async (err: any) => {
      const originalConfig = err.config;

      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          if (err.response.request.responseURL.includes('refresh')) {
            // toast.error(err.response.data.message, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            localStorage.clear();
            navigate('login');

            return Promise.reject(err);
          }
          originalConfig._retry = true;

          try {
            const res: any = await axiosRefresh.post('', {});
            const accessToken: any = res.data.data;
            localStorage.setItem('userToken', JSON.stringify(accessToken.token));
            localStorage.setItem(
              'refreshToken',
              JSON.stringify(accessToken.refresh_token)
            );
            axiosInstance.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${accessToken.token.access_token}`;
            return axiosInstance(originalConfig);
          } catch (_error: any) {
            localStorage.clear();
            navigate('login');
            toast.error(_error.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'uniqueId',
            });
            return Promise.reject(_error);
          }
        }
        if (err.response.status === 422) {
          for (const key in err.response.data.errors) {
            const element = err.response.data.errors[key];
            for (const errMessage of element) {
              toast.error(errMessage, {
                position: toast.POSITION.TOP_CENTER,
                toastId: 'uniqueId',
              });
            }
          }
        }
        if (err.response.status === 403) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        }
        if (err.response.status === 500) {
          toast.error('something went wrong\nplease contact the it', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        }
        if (err.response.status === 404) {
          toast.error('something went wrong\nplease contact the it', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        }
        if (err.response.status === 0) {
          toast.error('No Internet connection', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        }
      }

      return Promise.reject(err);
    }
  );
  return <></>;
};

export default Interceptors;
