import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';

export interface selectedEleState {
  ele: any;
}

const initialState: selectedEleState = {
  ele: {},
};

export const eleSlice = createSlice({
  name: 'ele',
  initialState,

  reducers: {
    setEle: (state, actions) => {
      state.ele = actions.payload;
    },
    removeEle: (state) => initialState,
  },
});

export const { setEle, removeEle } = eleSlice.actions;

export const selectElement = (state: RootState) => state.ele;

export default eleSlice.reducer;
