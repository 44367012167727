import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';

export interface openDialog {
  open: boolean;
  header: string;
  body: any;
  id: number;
  accept?: Function;
  reject?: Function;
}

const initialState: { state: openDialog } = {
  state: {
    open: false,
    header: '',
    body: '',
    id: 0,
    accept: () => {
      return;
    },
    reject: () => {
      return;
    },
  },
};

export const dialogeSlice = createSlice({
  name: 'dialoge',
  initialState,

  reducers: {
    openConfirmationDialoge: (state, actions) => {
      state.state = { ...actions.payload };
    },
    closeConfirmationDialoge: () => initialState,
  },
});

export const { openConfirmationDialoge, closeConfirmationDialoge } =
  dialogeSlice.actions;

export const confirmationSelector = (state: RootState) => state.confirmation.state;

export default dialogeSlice.reducer;
