import React from 'react';
import {
  dialogPdf,
  selectDialogPdf,
} from '../../service/store/openPdf/openPdfSlice';
import { useAppDispatch, useAppSelector } from '../../core/hooks';
import { PDFViewer } from '@react-pdf/renderer';
import { Dialog } from 'primereact/dialog';
import {
  removeEle,
  selectElement,
} from '../../service/store/selectedEle/selectedEleSlice';
import PdfRender from '../../components/PdfRender/PdfRender';
import ReactHtmlParser from 'react-html-parser';
import { unSelectCol } from '../../service/store/selectedCol/selectedColSlice';

const ShowPdf = () => {
  const dispatch = useAppDispatch();
  // to convert the data without style
  const pdfData = useAppSelector(selectElement);
  let ele = { ...pdfData.ele };
  const getContent = (html: any) => {
    let message = '';
    const element = ReactHtmlParser(html);
    element.forEach((ele) => {
      ele.props.children.forEach((child: string) => {
        message = message.concat(child);
      });
    });

    return message;
  };

  ele.details = getContent(pdfData.ele.details);
  ele.effect = getContent(pdfData.ele.effect);
  ele.recommended_solution = getContent(pdfData.ele.recommended_solution);

  //get user info
  const dialogue = useAppSelector(selectDialogPdf);
  console.log('dialog', dialogue);

  return (
    // open dialogue with pdf data
    <Dialog
      header="pdf"
      visible={dialogue.openPdf}
      style={{ width: '100vw', height: '100vh' }}
      onHide={() => {
        dispatch(dialogPdf(false));
        dispatch(unSelectCol());
        dispatch(removeEle());
      }}
      position="right"
      draggable={false}
    >
      {/* the viewer */}
      {ele.id ? (
        <PDFViewer>
          <PdfRender ele={ele} />
        </PDFViewer>
      ) : null}
    </Dialog>
  );
};

export default ShowPdf;
