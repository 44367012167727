import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import weekOfMonth from '../../core/getDayOfTheMonth';
import pdfHeader from '../../assets/images/pdf-header.png';
import secondHeader from '../../assets/images/second-header.png';
import waterMarkImg from '../../assets/images/watermark.png';
import rightWing from '../../assets/images/right-wing.png';

//pdf style
const styles = StyleSheet.create({
  cover: {
    flexDirection: 'row',
    backgroundColor: '#484848',
    flexWrap: 'wrap',
    position: 'relative',
  },
  imageView: {
    width: '50%',
    height: '100%',
    marginTop: 80,
  },
  metaView: {
    width: '50%',
  },
  headerImage: {},
  title: {},
  primaryText: {
    color: '#D28D33',
    fontSize: 20,
  },
  secondaryColor: {
    color: '#fff',
    marginTop: 5,
    marginBottom: 25,
    fontSize: 14,
  },
  page: {
    backgroundColor: '#484848',
    position: 'relative',
    padding: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  fixedHeader: {
    // height: 50,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    backgroundColor: '#F5F5F5',
    borderRadius: 10,
    marginBottom: 10,
  },
  headerImg: {
    width: 180,
    alignSelf: 'flex-end',
    textAlign: 'right',
    padding: 10,
  },
  contentBody: {
    width: '100%',
    // marginTop: 10,
    minHeight: '80vh',
    // maxHeight: '80vh',
    backgroundColor: '#F5F5F5',
    borderRadius: 10,
    position: 'relative',
    padding: 30,
    marginBottom: 20,
    overflow: 'hidden',
  },
  waterMark: { position: 'absolute', width: '100%' },
  problemTitle: { color: '#D28D33', fontSize: 20, margin: '10 0 ' },
  problemBody: {
    color: '#484848',
    fontSize: 14,
    padding: 14,
    backgroundColor: '#fff',
    border: 1,
    borderColor: '#cccccc',
    borderStyle: 'solid',
    marginBottom: 20,
    borderRadius: 4,
    lineHeight: 1.4,
  },
  imgGallery: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  problemImg: {
    width: '15vh',
    height: 100,
    border: 1,
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderRadius: 4,
    margin: 10,
  },
  footerView: {
    width: '100%',
    margin: '0 auto',
    position: 'absolute',
    bottom: 10,
    left: 10,
  },
  footerText: { color: '#fff', fontSize: 10 },
});

const PdfRender = (props: any) => (
  <>
    <Document>
      <Page size="A4" style={styles.cover}>
        <View style={styles.metaView}>
          <Image
            style={{ paddingLeft: '20px', marginTop: '16px' }}
            src={pdfHeader}
          />
          <Text
            style={{
              margin: '50px',
              marginBottom: '0',
              color: '#fff',
              padding: '20px',
              width: '300px',
              backgroundColor: '#414141',
            }}
          >
            {props.ele.date} | (week {weekOfMonth()})
          </Text>
          <View
            style={{
              margin: '10px',
              marginLeft: '50px',
              color: '#fff',
              padding: '20px',
              width: '300px',
              backgroundColor: '#414141',
            }}
          >
            <Text style={styles.primaryText}>Report Name</Text>
            <Text style={styles.secondaryColor}>
              {props.ele.name ? props.ele.name : props.ele.category.name}
            </Text>
            <Text style={styles.primaryText}>Task Name</Text>
            <Text style={styles.secondaryColor}>{props.ele.task_name}</Text>
            <Text style={styles.primaryText}>Department User/s</Text>
            <Text style={styles.secondaryColor}>
              {props.ele.allowed_view_users.length > 0
                ? props.ele.allowed_view_users.map(
                    (emp: { id: number; name: string }) => {
                      return `${emp.name}, `;
                    }
                  )
                : ''}
            </Text>
            <Text style={styles.primaryText}>Professional/s</Text>
            <Text style={styles.secondaryColor}>
              {props.ele.issue_employees.length > 0
                ? props.ele.issue_employees.map(
                    (emp: { id: number; name: string }) => {
                      return `${emp.name}, `;
                    }
                  )
                : ''}
            </Text>
            <Text style={styles.primaryText}>Department/s</Text>
            <Text style={styles.secondaryColor}>
              {props.ele.issue_departments.length > 0
                ? props.ele.issue_departments.map(
                    (dep: { id: number; name: string }) => {
                      return `${dep.name}, `;
                    }
                  )
                : ''}
            </Text>
          </View>
        </View>

        <View style={styles.imageView}>
          <Image src={rightWing}></Image>
        </View>
        <View
          style={{
            width: '100%',
            margin: '0 auto',
            position: 'absolute',
            bottom: 10,
            left: 10,
          }}
        >
          <Text style={{ color: '#fff', fontSize: 10 }}>
            Powered By caduceuslane &copy; Copyright 2021. All rights reserved.
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.fixedHeader}>
          <Image fixed style={styles.headerImg} src={secondHeader} />
        </View>
        <View style={styles.contentBody}>
          <Image fixed src={waterMarkImg} style={styles.waterMark} />
          <Text style={styles.problemTitle}>Problem details</Text>
          <Text style={styles.problemBody}>{props.ele.details}</Text>
          <Text style={styles.problemTitle}>Problem Impact</Text>
          <Text style={styles.problemBody}>{props.ele.effect}</Text>
          <Text style={styles.problemTitle}>Recommended Solution</Text>
          <Text style={styles.problemBody}>{props.ele.recommended_solution}</Text>
          {props.ele.comment ? (
            <View style={styles.sectionArea}>
              <Text style={styles.problemTitle}>Comment</Text>
              <Text style={styles.problemBody}>{props.ele.comment}</Text>
            </View>
          ) : null}
          <Text style={styles.problemTitle}>Image</Text>
          <View style={styles.imgGallery}>
            {props.ele.images.map((image: any) => {
              return (
                <Image key={image.id} style={styles.problemImg} src={image.url} />
              );
            })}
          </View>
        </View>
        <View fixed style={styles.footerView}>
          <Text style={styles.footerText}>
            Powered By caduceuslane &copy; Copyright 2021. All rights reserved.
          </Text>
        </View>
      </Page>
    </Document>
  </>
);

export default PdfRender;
