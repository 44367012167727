import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';

export interface openDialog {
  open: boolean;
  type: string;
  id: number;
  imagesIds?: any[];
}

const initialState: openDialog = {
  open: false,
  type: 'add',
  id: 0,
};

export const dialogeSlice = createSlice({
  name: 'dialoge',
  initialState,

  reducers: {
    openDialog: (state, actions) => {
      state.open = actions.payload.open;
      state.type = actions.payload.type;
      state.id = actions.payload.id;
      if (actions.payload.imagesIds) state.imagesIds = actions.payload.imagesIds;
    },
    setImageIds: (state, action) => {
      state.imagesIds = action.payload;
    },
  },
});

export const { openDialog, setImageIds } = dialogeSlice.actions;

export const dialogeSelector = (state: RootState) => state.dialoge;

export default dialogeSlice.reducer;
