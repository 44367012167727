import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';

const initialState: { canDo: string[] } = {
  canDo: [],
};

export const permissionSlice = createSlice({
  name: 'permissions',
  initialState,

  reducers: {
    setPermissions: (state, actions) => {
      state.canDo = [...actions.payload];
    },
    removePermissions: (state) => {
      return initialState;
    },
  },
});

export const { setPermissions, removePermissions } = permissionSlice.actions;

export const selectPermissions = (state: RootState) => state.permissions.canDo;

export default permissionSlice.reducer;
