import React, { useEffect, useState } from 'react';
import {
  Warper,
  Content,
  ImgLogo,
  Mask,
  Title,
  SubTitle,
  UserInfor,
  MenuWarper,
  MenuItem,
  MenuList,
} from './SideMenu.style';
import user from '../../../assets/images/logo.png';

import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { signOut } from '../../../service/store/auth/authSlice';
// import { logout } from '../../../service/store/auth/auth.api';
import {
  removeUserInfo,
  selectUserInfo,
} from '../../../service/store/userInfo/userInfoSlice';

import { useLocation, useNavigate } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { removePermissions } from '../../../service/store/permissions/permissionsSlice';

const SideMenu = (props: any) => {
  const [firstEnter, setFirstEnter] = useState<boolean>(true);
  const userInfo = useAppSelector(selectUserInfo);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('solved-problems')) props.setActiveDiv(1);
    else if (location.pathname.includes('groups')) props.setActiveDiv(2);
    else if (location.pathname.includes('team-lead')) props.setActiveDiv(3);
    else if (location.pathname.includes('departments')) props.setActiveDiv(4);
    else if (location.pathname.includes('predefined-titles')) props.setActiveDiv(5);
    else if (location.pathname.includes('professionals')) props.setActiveDiv(6);
    else props.setActiveDiv(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //main actions dispatch
  const dispatch = useAppDispatch();

  //navigateor
  const navigate = useNavigate();

  // confirmation when logout
  const confirmApprove = () => {
    confirmDialog({
      message: 'Are you sure to want logout?',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject,
    });
  };

  /**
   * @todo
   * logout
   * remove the tokens from localStorage
   * navigate to login page
   */
  const accept = async () => {
    navigate('/login');
    // await logout();
    dispatch(signOut());
    dispatch(removeUserInfo());
    dispatch(removePermissions());
    localStorage.clear();
    setFirstEnter(true);
  };
  const reject = () => {};

  /**
   * side Menu Items
   * @label the item name
   * @Icon the item iconName
   * @command navigate throw pages
   * @isActive to show the active class on the selected item
   * @show to prevent unauthorized users from entering the pages
   */
  const items = [
    {
      show: true,
      label: 'Reports',
      icon: 'pi pi-home p-mr-2',
      command: () => {
        props.setActiveDiv(0);
        navigate('/');
        setFirstEnter(false);
      },
      isActive: props.activeDiv === 0 ? true : false,
    },
    {
      show: true,
      label: 'Solved Reports',
      icon: 'pi pi-book p-mr-2',
      command: () => {
        props.setActiveDiv(1);
        navigate('/solved-problems');
        setFirstEnter(false);
      },
      isActive: props.activeDiv === 1 ? true : false,
    },
    {
      show: userInfo.role.name === 'admin',
      label: 'Groups',
      icon: 'pi pi-users p-mr-2',
      command: () => {
        props.setActiveDiv(2);
        navigate('/groups');
        setFirstEnter(false);
      },
      isActive: props.activeDiv === 2 ? true : false,
    },
    {
      show: userInfo.role.name === 'admin',
      label: 'Users',
      icon: 'pi pi-user p-mr-2',
      command: () => {
        props.setActiveDiv(3);
        navigate('/team-lead');
        setFirstEnter(false);
      },
      isActive: props.activeDiv === 3 ? true : false,
    },
    {
      show: userInfo.role.name === 'admin',
      label: 'Departments',
      icon: 'pi pi-briefcase p-mr-2',
      command: () => {
        props.setActiveDiv(4);
        navigate('/departments');
        setFirstEnter(false);
      },
      isActive: props.activeDiv === 4 ? true : false,
    },
    {
      show: userInfo.role.name === 'admin',
      label: 'Report title',
      icon: 'pi pi-book p-mr-2',
      command: () => {
        props.setActiveDiv(5);
        navigate('/predefined-titles');
        setFirstEnter(false);
      },
      isActive: props.activeDiv === 5 ? true : false,
    },
    {
      show: userInfo.role.name === 'admin',
      label: 'Professionals',
      icon: 'pi pi-user p-mr-2',
      command: () => {
        props.setActiveDiv(6);
        navigate('professionals');
        setFirstEnter(false);
      },
      isActive: props.activeDiv === 6 ? true : false,
    },
    {
      show: true,
      label: 'Logout',
      icon: 'pi pi-sign-out p-mr-2',
      command: () => {
        // setAciveDiv(5);
        confirmApprove();
      },
      // isActive: props.activeDiv === 6 ? true : false,
    },
  ];

  return (
    <Warper firstEnter={firstEnter}>
      <Content>
        <Mask></Mask>
        <UserInfor>
          <ImgLogo src={user} />
          <Title>{userInfo.username}</Title>
          <SubTitle>{userInfo.role?.name}</SubTitle>
        </UserInfor>
        <MenuWarper>
          <MenuList>
            {/* render the items */}
            {items.map((i: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  {i.show ? (
                    <MenuItem
                      className={i.isActive ? 'active' : ''}
                      key={index}
                      onClick={i.command}
                    >
                      <i className={i.icon} style={{ marginRight: '.3rem' }}></i>
                      <span>{i.label}</span>
                    </MenuItem>
                  ) : null}
                </React.Fragment>
              );
            })}
          </MenuList>
        </MenuWarper>
      </Content>
    </Warper>
  );
};

export default SideMenu;
