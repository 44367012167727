import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';

export interface pdfState {
  openPdf: boolean;
}

const initialState: pdfState = {
  openPdf: false,
};

export const pdfSlice = createSlice({
  name: 'pdf',
  initialState,

  reducers: {
    dialogPdf: (state, actions) => {
      state.openPdf = actions.payload;
    },
  },
});

export const { dialogPdf } = pdfSlice.actions;

export const selectDialogPdf = (state: RootState) => state.pdf;

export default pdfSlice.reducer;
