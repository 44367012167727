import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './core/Routes';
import Interceptors from './core/Interceptors';
import Spinner from './components/Spinner/Spinner';
import { ToastContainer } from 'react-toastify';

import './App.css';

function App() {
  return (
    <Suspense fallback={<Spinner />}>
      <Router>
        {/* show that component when you load your lazy component */}
        <Routes />
        <Interceptors />
        <ToastContainer />
      </Router>
    </Suspense>
  );
}

export default App;
