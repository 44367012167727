import moment from 'moment';
import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { selectAuth, signIn } from '../service/store/auth/authSlice';
import Home from '../layout/Home/Home';
import { useAppDispatch, useAppSelector } from './hooks';

const Routes = () => {
  const dispatch = useAppDispatch();
  // lazy load the component
  const Login = lazy(() => import('../layout/Login/Login'));
  const Department = lazy(() => import('../layout/Home/Department/Department'));
  const PredefinedTitles = lazy(
    () => import('../layout/Home/PredefinedTitles/PredefinedTitles')
  );
  const Employee = lazy(() => import('../layout/Home/Employee/Employee'));
  const Problem = lazy(() => import('../layout/Home/Problem/Problem'));
  const SolvedProblems = lazy(
    () => import('../layout/Home/SolvedProblems/SolvedProblems')
  );
  const UserGroups = lazy(() => import('../layout/Home/UserGroups/UserGroups'));
  const Users = lazy(() => import('../layout/Home/Users/Users'));
  const Notification = lazy(
    () => import('../layout/Home/Notification/Notification')
  );

  const NotFound = lazy(() => import('../layout/NotFound/NotFound'));

  //to guard the main route if you are not loggedin
  const token = localStorage.getItem('userToken');
  if (token && moment(JSON.parse(token).expired_at) >= moment()) {
    dispatch(signIn(JSON.parse(token).access_token));
  }
  const refreshToken = localStorage.getItem('refreshToken');
  // if you login without remember_me to keep you login during the session
  const auth = useAppSelector(selectAuth);
  const routes = useRoutes([
    { path: 'login', element: <Login /> },
    {
      path: '/',
      element:
        auth.isAuth ||
        (token && moment(JSON.parse(token).expired_at) >= moment()) ||
        (refreshToken && moment(JSON.parse(refreshToken).expired_at) >= moment()) ? (
          <Home />
        ) : (
          <Navigate to="/login" />
        ),
      children: [
        { path: '', element: <Problem /> },
        { path: 'solved-problems', element: <SolvedProblems /> },
        { path: 'groups', element: <UserGroups /> },
        { path: 'team-lead', element: <Users /> },
        { path: 'departments', element: <Department /> },
        { path: 'predefined-titles', element: <PredefinedTitles /> },
        { path: 'professionals', element: <Employee /> },
        { path: 'notification', element: <Notification /> },
      ],
    },
    {
      path: '*',
      element:
        auth.isAuth ||
        (token && moment(JSON.parse(token).expired_at) >= moment()) ||
        (refreshToken && moment(JSON.parse(refreshToken).expired_at) >= moment()) ? (
          <NotFound />
        ) : (
          <Navigate to="/login" />
        ),
    },
  ]);
  return routes;
};

export default Routes;
