import styled from 'styled-components';
import { slideFromLeft } from './Problem/Problem.style';

export const Warper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 11rem 1fr;
  grid-gap: 1rem;
`;

export const UserTitle = styled.p`
  animation: ${slideFromLeft} 1s ease-in-out;
`;
