import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Warper } from './Home.style';
import { Content } from './Problem/Problem.style';

import SideMenu from './SideMenu/SideMenu';
import Header from './Header/Header';
import ShowPdf from '../../components/ShowPdf/ShowPdf';

import { useAppDispatch } from '../../core/hooks';
import { getUserInfo } from '../../service/store/userInfo/user.api';
import { setUserInfo } from '../../service/store/userInfo/userInfoSlice';
import { UserInfo } from '../../service/store/userInfo/userInfo.interface';
import { setPermissions } from '../../service/store/permissions/permissionsSlice';
import Spinner from '../../components/Spinner/Spinner';

import './Home.css';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [activeDiv, setActiveDiv] = useState<number>(0);
  //main reducers actions dispatch
  const dispatch = useAppDispatch();

  /**
   * @todo
   * make http call to get the user info and storage it in the store
   */
  const getUser = async () => {
    const user: UserInfo = await getUserInfo();
    const permissions: string[] = user.permissions.map((p) => {
      return p.name;
    });
    dispatch(setPermissions(permissions));
    dispatch(setUserInfo({ ...user }));
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Warper>
      <SideMenu activeDiv={activeDiv} setActiveDiv={setActiveDiv} />
      <Content>
        <Header setLoading={setLoading} setActiveDiv={setActiveDiv} />
        <Outlet />
      </Content>
      <ShowPdf />
      {loading ? <Spinner /> : null}
    </Warper>
  );
};

export default Home;
