import styled from 'styled-components';

// make the warper take the full view with dark background
export const SpinnerWarper = styled.div`
  width: 100%;
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  top: 0;
  left: 0;
`;

// center the spinner
export const SpinnerContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
