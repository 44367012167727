import styled, { keyframes } from 'styled-components';

export const slideFromLeft = keyframes`
  0%{
    transform: translateX(-1rem) ;
    opacity:0
  }
  100%{
    transform: translateX(0) ;
    opacity:1
    
  }
`;

export const DateTitles = styled.div`
  border-left: 2px;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-color: var(--primary-color);
  border-style: solid;
  padding-left: 6.5px;
  margin-top: 4.4rem;
  margin-bottom: 1.6rem;
  animation: ${slideFromLeft} 1s ease-in-out;
`;

export const DataTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 4rem;
  margin-bottom: 5px;
  /* display: inline-block; */
`;
export const WeekTitle = styled.span`
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 6rem;
  margin-left: 0.2rem;
  margin-bottom: 0;
`;

export const NotificationIcon = styled.i`
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--primary-color);
  color: var(--primary-color);
`;

export const Counter = styled.span`
  position: fixed;
  font-size: 12px;
  top: 4.4rem;
  right: 0.7rem;
  padding: 2px 6px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
`;

export const NotificationWarper: any = styled.div`
  position: fixed;
  top: 7rem;
  right: 1rem;
  padding: 1rem;
  border-radius: 0.4rem;
  border: 1px solid #fff;
  background-color: #fff;
  min-width: auto;
  box-shadow: 0px 0px 6px 4px rgb(0 0 0 / 16%);
  transform-origin: top;
  z-index: 999;
  height: ${(props: any) => (props.height ? 'auto' : '0')};
  transform: all 2s ease-in-out;
`;

export const NotificationTitle = styled.div`
  cursor: pointer;
  text-align: right;
  text-decoration: underline;
  color: var(--primary-color);
`;

export const NotificationBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  div.NotificationTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    div.logo {
      background-color: var(--primary-color);
      width: 44px;
      height: 44px;
      border-radius: 50%;
      color: #fff;
      margin-right: 19px;
      padding: 15px;
    }
  }
  div.NotificationDate {
    margin-left: 1rem;
    font-size: 14px;
  }
`;

export const Mask = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 800;
`;

export const NoNotification = styled.div`
  color: var(--primary-color);
  text-align: center;
  padding: 1rem;
  font-size: 1rem;
  font-style: italic;
  text-decoration: underline;
`;
