import React, { useEffect, useState } from 'react';
import {
  Counter,
  DataTitle,
  DateTitles,
  NotificationWarper,
  NotificationTitle,
  WeekTitle,
  NotificationBody,
  NoNotification,
  Mask,
} from './Header.style';
import weekOfMonth from '../../../core/getDayOfTheMonth';
import {
  getNotifications,
  readNotification,
} from '../../../service/API/notifications';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { setNotifyId } from '../../../service/store/notifications/notificationsSlice';
import { getProblemById } from '../../../service/API/problem.api';
import { selectUserInfo } from '../../../service/store/userInfo/userInfoSlice';

const Header = (props: { setLoading: Function; setActiveDiv: Function }) => {
  const [showNotifications, setNotificationsState] = useState<boolean>(false);
  const user = useAppSelector(selectUserInfo);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [updateNotifications, setUpdateNotifications] = useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentThur = moment().startOf('W').add('5', 'days');

  const fetchNotifications = async () => {
    const notify = await getNotifications();
    setNotifications(notify);
  };

  useEffect(() => {
    fetchNotifications();
  }, [updateNotifications]);

  return (
    <>
      {/* <Button label="open" onClick={() => setDialoge(true)} /> */}
      <DateTitles>
        <DataTitle>{moment().format('DD MMM,YYYY')}</DataTitle>
        <WeekTitle> - Week {weekOfMonth(currentThur)}</WeekTitle>
      </DateTitles>
      {user.role.name !== 'admin' ? (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setNotificationsState((notify: boolean) => {
              return !notify;
            });
          }}
        >
          <FontAwesomeIcon icon={faBell} />
          {notifications.length > 0 ? (
            <Counter>{notifications.length}</Counter>
          ) : null}
        </div>
      ) : null}
      {showNotifications ? (
        <>
          <Mask
            onClick={() => {
              setNotificationsState(false);
            }}
          />
          <NotificationWarper height={showNotifications}>
            {notifications.length > 0 ? (
              <>
                <NotificationTitle
                  onClick={() => {
                    setNotificationsState(false);
                    props.setActiveDiv(-1);
                    navigate('/notification');
                    setNotifications([]);
                  }}
                >
                  See All
                </NotificationTitle>
                {notifications.map((notify: any) => {
                  return (
                    <NotificationBody key={notify.problem_id}>
                      <div className="NotificationTitle">
                        <div className="logo">
                          <FontAwesomeIcon icon={faStickyNote} size="lg" />
                        </div>
                        <div
                          onClick={async () => {
                            props.setLoading(true);
                            const problem = await getProblemById(notify.problem_id);
                            readNotification(notify.id);
                            setUpdateNotifications(updateNotifications + 1);
                            dispatch(setNotifyId(problem));
                            if (location.pathname !== '/') {
                              navigate('/');
                              props.setActiveDiv(0);
                            }
                            setNotificationsState(false);
                            props.setLoading(false);
                          }}
                        >
                          New Report ({notify.problem_name})
                        </div>
                      </div>
                      <div className="NotificationDate">{notify.problem_date}</div>
                    </NotificationBody>
                  );
                })}
              </>
            ) : (
              <NoNotification>You Have No Notification</NoNotification>
            )}
          </NotificationWarper>
        </>
      ) : null}
    </>
  );
};

export default Header;
