import React from 'react';
import Loader from 'react-loader-spinner';
import { SpinnerWarper, SpinnerContent } from './Spinner.style';

/**
 *
 * @returns the spinner component
 */
const Spinner = () => {
  return (
    // warper to get the full width
    <SpinnerWarper>
      {/* center the content */}
      <SpinnerContent>
        {/* loader style */}
        <Loader type="Grid" color="var(--primary-color)" height={100} width={100} />
      </SpinnerContent>
    </SpinnerWarper>
  );
};

export default Spinner;
