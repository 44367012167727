import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../core/store';

const initialState: { data: any } = {
  data: null,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,

  reducers: {
    setNotifyId: (state, actions) => {
      state.data = actions.payload;
    },
  },
});

export const { setNotifyId } = notificationsSlice.actions;

export const notificationsSelector = (state: RootState) => state.notifications.data;

export default notificationsSlice.reducer;
